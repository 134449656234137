import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import LoginPage from "./pages/Login";
import WelcomePage from "./pages/Welcome";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={ <LoginPage/> } />
        <Route path="/welcome" element={ <WelcomePage/> } />
        <Route path="/privacy-policy" element={ <PrivacyPolicyPage/> } />
      </Routes>
    </Router>
  );
}

export default App;