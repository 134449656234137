import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import '../index.css'

function WelcomePage() {
  
  let navigate = useNavigate();

  useEffect(() => {

    let _isAuthenticated = localStorage.getItem('elphnextnsn-lgn');
    
    if (!_isAuthenticated)
      navigate('/');

  });

  const doLogout = async (event) => {

    event.preventDefault();

    document.cookie = "elphnextnsn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Lax";
    localStorage.removeItem('elphnextnsn-lgn');

    navigate('/');

  }

  return (

    <div>
        <button
          type="submit"
          className="flex justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          onClick={doLogout}
        >
            Logout
        </button>
    </div>
  )

}

export default WelcomePage;
