import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import '../index.css'

function LoginPage() {

  let navigate = useNavigate();

  useEffect(() => {

    /*
    let _isAuthenticated = localStorage.getItem('elphnextnsn-lgn');
    
    if (_isAuthenticated)
      navigate('/welcome');
    else
      navigate('/');
    */

  });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');  

  const doLogin = async (event) => {

    event.preventDefault();

    let _validateLogin = await fetch('https://back.elephan.dev/logins/validate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email, password: password })
    });

    let _response = await _validateLogin.json()

    if (_response.data.valid) {

      document.cookie = `elphnextnsn=${JSON.stringify(_response.data)};`;
      localStorage.setItem('elphnextnsn-lgn', JSON.stringify(_response.data));

      navigate('/welcome');

    } else
      alert(_response.message)

  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-auto w-40"
            src="/logo192.png"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Hi, welcome to Elephan!
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="hi@elephan.ai"
                  onChange={e => setEmail(e.target.value)}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="text-sm">
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  placeholder="*****"
                  onChange={e => setPassword(e.target.value)}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={doLogin}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default LoginPage;