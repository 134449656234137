import '../index.css'

function PrivacyPolicyPage() {
  
  return (

    <div>
        <h1>Privacy Policy</h1>
        <p>Last updated: 17/Abr/2024</p>

        <br/>
        <p>This document explains how Elephan ("we", "us", or "our") collects, uses, and protects your personal information when you use our software and services ("Services"). We are committed to protecting your privacy and ensuring that your personal information is secure. For any questions or concerns, you may contact us at tech@elephan.dev</p>

        <br/>

        <h2>Information We Collect</h2>
        <ul>            
        <li><strong>Registration Data:</strong> When you register for our Service, we collect information such as your name, email address, and phone number.</li>
        <li><strong>Usage Data:</strong> We collect information on how you use our services, including the types of data accessed, features used, and frequency of use.</li>
        <li><strong>Technical Information:</strong> We may collect technical information about your device, including operating system, model, settings, and unique identifiers.</li>
        </ul>

        <br/>

        <h2>How We Use Your Information</h2>
        <ul>
        <li><strong>To Provide and Maintain Our Services:</strong> We use your information to operate and maintain our services, including providing customer support and personalized features.</li>
        <li><strong>To Improve Our Services:</strong> We analyze usage data to understand how our services are used and to help us improve them.</li>
        <li><strong>For Security Purposes:</strong> We use your information to enhance the security of our services, detect and prevent fraudulent activities.</li>
        </ul>

        <br/>

        <h2>How We Share Your Information</h2>
        <p>We do not share your personal information with third parties except as described in this policy:</p>
        <ul>
        <li><strong>With Service Providers:</strong> We may share your information with service providers who perform services on our behalf, such as hosting, data analysis, and customer service.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
        </ul>

        <br/>

        <h2>Your Rights</h2>
        <p>You have certain rights regarding your personal information, including:</p>
        <ul>
        <li><strong>Access and Update:</strong> You can review and update your personal information through your account settings.</li>
        <li><strong>Deletion:</strong> You can request the deletion of your personal information, subject to certain exceptions prescribed by law.</li>
        <li><strong>Object:</strong> You may object to the processing of your personal information, ask us to restrict processing of your information, or request portability of your information.</li>
        </ul>

        <br/>

        <h2>Security</h2>
        <p>We take steps to ensure that your personal information is treated securely and in accordance with this Privacy Policy. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure.</p>

        <br/>

        <h2>Changes to This Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

        <br/>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at tech@elephan.dev</p>

    </div>
  )

}

export default PrivacyPolicyPage;
